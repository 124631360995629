import { inject, Injectable, signal } from '@angular/core';
import { ACCOUNT_ROUTER_TOKENS, AUTH_ROUTER_TOKENS, AuthService } from '@webapp-nx-repo/lib-shared-auth';
import {
	ISideMenuCommand,
	SideMenuCmdsService,
} from '@webapp-nx-repo/lib-shared-shell';
import { CREW_NOTE_ROUTER_TOKENS } from '@webapp-nx-repo/lib-gp-crew-note';

@Injectable({
	providedIn: 'root',
})
export class MySideMenuCmdsService implements SideMenuCmdsService {
	private readonly authService = inject(AuthService);
	cmds = signal<ISideMenuCommand[]>([]);

	constructor() {
                
		const cmds_array: ISideMenuCommand[] = [
			{
				icon: 'groups',
				label: 'Note Equipaggio',
				route: CREW_NOTE_ROUTER_TOKENS.BASE_URL,
			},
		];

        /*
		if (this.authService.loggedUser().id === 1) {
			cmds_array.push({
				icon: 'keyboard_arrow_right',
				label: 'Imp. Giornalieri',
				cmds: [
					{
						label: 'Biglietterie',
						route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_OFFICE}`,
					},
					{
						label: 'Tipi linea',
						route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_TYPE}`,
					},
					{
						label: 'Linee',
						route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_LINE}`,
					},
					{
						label: 'Tratte',
						route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKET_MODEL}`,
					},
				],
			});

			cmds_array.push({
				icon: 'attach_money',
				label: 'Registry',
				route: `${TMT_TICKETS_ROUTER_TOKENS.BASE_URL}/${TMT_TICKETS_ROUTER_TOKENS.TICKETS_REGISTRY}`,
			});

			cmds_array.push({
				icon: 'attach_money',
				label: 'Statini',
				route: STATINI_ROUTER_TOKENS.BASE_URL,
			});
			cmds_array.push({
				icon: 'system_update_alt',
				label: 'Import',
				route: IMPORT_ROUTER_TOKENS.BASE_URL,
			});			
		}
        */

        cmds_array.push({
            icon: 'settings',
            label: `Impostazioni`,
            cmds: [
                {
                    label: 'Utenti',
                    route: `/${ACCOUNT_ROUTER_TOKENS.USERS}`,
                },
            ],
        });

        cmds_array.push({
            icon: 'manage_accounts',
            label: `Il tuo account`,
            route: `/${ACCOUNT_ROUTER_TOKENS.BASE_URL}`,
        });

		cmds_array.push({
			icon: 'logout',
			label: 'Esci',
			route: `/${AUTH_ROUTER_TOKENS.LOGIN}`,
			fn: this.authService.logout(),
		});

		this.cmds.set(cmds_array);
	}
}
