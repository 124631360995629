import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { SideMenuCmdsService } from '@webapp-nx-repo/lib-shared-shell';
import { ContentTypeInterceptor, provideAppInfo } from '@webapp-nx-repo/lib-shared-common';
import { environment } from '@webapp-nx-repo/lib-gp-common';
import { provideAuthConfig, provideProfileConfig, UnAuthenticatedInterceptor, XsrfInterceptor } from '@webapp-nx-repo/lib-shared-auth';
import { provideHttpClient, withInterceptors, withNoXsrfProtection } from '@angular/common/http';
import { MySideMenuCmdsService } from './SideMenuCmds/side-menu-cmds.service';
registerLocaleData(localeIt, 'it-IT');

export const appConfig: ApplicationConfig = {
	providers: [
		provideZoneChangeDetection({ eventCoalescing: true }),
		provideRouter(appRoutes),
        provideAnimationsAsync(),
        { provide: SideMenuCmdsService, useClass: MySideMenuCmdsService },
        provideAppInfo({
            srvUrl: environment.srvUrl,
            appVersion: '0.0.1',
            logoPath: 'img/logo.png',
            company: 'Golfo Paradiso Srl',
        }),
        provideAuthConfig({
            user_can_register: false,
            enable_two_factor: false,
        }),
        provideHttpClient(
            
            withNoXsrfProtection(),             // use XsrfInterceptor below
            
            withInterceptors([
                UnAuthenticatedInterceptor,     // if (error.status === 401) ==> router.navigateByUrl('/login');
                ContentTypeInterceptor,         // req.headers.set('Accept', 'application/json'), withCredentials: true
                XsrfInterceptor,                // req.clone({ headers: req.headers.set('X-XSRF-TOKEN', csrfToken) });
            ]),
        ),
        { provide: LOCALE_ID, useValue: 'it-IT' },
        provideProfileConfig({
            cf: true,
            date_of_birth: false,
            birth_place: false,
            place_of_residence: false,
            phone: false,
            iban: true,
        }),
	],
};
