import { Route } from '@angular/router';
import { auth_routes, loggedInRouteGuard } from '@webapp-nx-repo/lib-shared-auth';
import { Error404Component } from '@webapp-nx-repo/lib-shared-common';

export const appRoutes: Route[] = [
    //
    // --- PUBLIC ZONE ---
    //
    // login, login2fa, register, forgot-pwd, reset-pwd
    //
    ...auth_routes,
    //
    { 
        path: '',
        canActivateChild: [loggedInRouteGuard()],   // --> LOGGED ZONE
        loadChildren: () => import('./main/main.routes').then(m => m.MAIN_ROUTES),
    },
    {
        path: '**',
        component: Error404Component
    }    
];
